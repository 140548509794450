<template>
  <vue-final-modal
    class="modal-reset-pass"
    :click-to-close="false"
    :overlay-transition="{ mode: 'in-out', duration: 250 }"
    :content-transition="{ mode: 'in-out', duration: 250 }"
  >
    <div class="modal-reset-pass__container">
      <div class="modal-reset-pass__close" @click="closeModal(ModalName.RESET_PASS)">
        <atomic-icon id="close" class="modal-reset-pass__close--icon" />
      </div>

      <div class="modal-reset-pass__title">
        {{ getContent(currentLocaleData, defaultLocaleData, 'resetPassword') }}
      </div>

      <div class="modal-reset-pass__text">
        {{ getContent(currentLocaleData, defaultLocaleData, 'resetPasswordText') }}
      </div>

      <form-reset-pass :currentLocaleData="currentLocaleData" :defaultLocaleData="defaultLocaleData" />
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';

  import { ModalName } from '@skeleton/consts/modals';
  import type { IModalsContent } from '~/types';

  defineProps<{
    currentLocaleData: Maybe<IModalsContent['authorization']>;
    defaultLocaleData: Maybe<IModalsContent['authorization']>;
  }>();

  const { closeModal } = useModalStore();
  const { getContent } = useProjectMethods();
</script>

<style src="~/assets/styles/components/modal/reset-pass.scss" lang="scss" />
