<template>
  <div class="form-reset-pass">
    <div class="form-reset-pass__wrap">
      <form-input-password
        v-model:value="resetFormData.newPassword"
        type="password"
        name="newPassword"
        label=""
        :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.newPassword.placeholder')"
        is-required
        :hint="setError('newPassword')"
        @blur="v$.newPassword?.$touch()"
        @focus="onFocus('newPassword')"
        @input="inputNewPassword"
      />

      <form-input-password
        v-model:value="resetFormData.repeatNewPassword"
        type="password"
        name="repeatNewPassword"
        label=""
        :placeholder="
          getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.repeatNewPassword.placeholder')
        "
        is-required
        :hint="setError('repeatNewPassword')"
        @blur="v$.repeatNewPassword?.$touch()"
        @focus="onFocus('repeatNewPassword')"
      />
    </div>

    <button-base
      tagName="div"
      type="primary"
      size="md"
      :isDisabled="v$.$invalid || isLockedAsyncButton"
      :isProcessing="isLockedAsyncButton"
      @click="resetPassword"
    >
      {{ getContent(currentLocaleData, defaultLocaleData, 'save') }}
    </button-base>
  </div>
</template>

<script setup lang="ts">
  import { ModalName } from '@skeleton/consts/modals';
  import type { IModalsContent } from '~/types';

  defineProps<{
    currentLocaleData: Maybe<IModalsContent['authorization']>;
    defaultLocaleData: Maybe<IModalsContent['authorization']>;
  }>();

  const globalStore = useGlobalStore();
  const { fieldsSettings, defaultLocaleFieldsSettings, alertsData, defaultLocaleAlertsData } = storeToRefs(globalStore);
  const { getFormRules, createValidationRules, getContent } = useProjectMethods();

  const route = useRoute();

  const resetFormData = reactive({
    newPassword: '',
    repeatNewPassword: '',
  });

  const resetCode = ref('');

  const resetRules = createValidationRules(Object.keys(resetFormData).map(field => ({ name: field })));
  const resetFormRules = getFormRules(resetRules);
  const { serverFormErrors, v$, onFocus, setError } = useFormValidation(resetFormRules, resetFormData);

  const inputNewPassword = (): void => {
    if (v$.value.repeatNewPassword.$dirty) {
      const oldValue = resetFormData.repeatNewPassword;
      resetFormData.repeatNewPassword = '';
      resetFormData.repeatNewPassword = oldValue;
    }
  };

  const { showAlert } = useLayoutStore();
  const { closeModal } = useModalStore();

  const showErrorAlert = (): void => {
    showAlert(alertsData.value?.profile?.invalidResetCode || defaultLocaleAlertsData.value?.profile?.invalidResetCode);
  };

  const isLockedAsyncButton = ref<boolean>(false);
  const { resetProfilePassword } = useCoreProfileApi();
  const resetPassword = async (): Promise<void> => {
    if (v$.value.$invalid) return;
    v$.value.$reset();
    const validFormData = await v$.value.$validate();
    if (!validFormData) return;

    try {
      isLockedAsyncButton.value = true;
      const res = { ...resetFormData, code: resetCode.value as string };
      await resetProfilePassword(res);
      showAlert(alertsData.value?.profile?.passwordChanged || defaultLocaleAlertsData.value?.profile?.passwordChanged);
      closeModal(ModalName.RESET_PASS);
    } catch (error: any) {
      if (error.response?.status === 422) {
        if (error.data?.error?.fields.code) showErrorAlert();
        else serverFormErrors.value = error.data?.error?.fields;
      } else showErrorAlert();
    } finally {
      isLockedAsyncButton.value = false;
    }
  };

  onBeforeMount(() => {
    resetCode.value = route.query.resetCode as string;
  });
</script>

<style src="~/assets/styles/components/form/reset-pass.scss" lang="scss" />
